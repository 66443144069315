import { ItemField } from '@common/components/selection/ItemSelectionContentField/ItemSelectionContenField.props'
import { createSlice } from '@reduxjs/toolkit'
import { ItemInterface, SelectionPageReducerInterface } from '@store/interfaces'

const initialState: SelectionPageReducerInterface = {
  callback: () => {},
  loading: false,
  items: [],
  itemsSelected: [],
  error: false,
  fieldNameRender: '',
  fieldIsSelected: '',
  checkDisableItems: false,
  HeaderRender: undefined,
  numItemsSelected: 0, // length of field selected
  sumFieldSelected: 0, // sum surface field selected
  fieldSum: undefined,
}

const selectionPageSlice = createSlice({
  name: 'selectionPage',
  initialState,
  reducers: {
    resetDataSelection(state) {
      state = initialState
      return state
    },
    setItemsSelection(state, action) {
      const {
        callback,
        items,
        fieldNameRender,
        fieldIsSelected,
        HeaderRender,
        fieldSum,
      } = action.payload

      state.callback = callback || state.callback
      state.items = items || state.items
      state.loading = false
      state.error = false
      state.fieldNameRender = fieldNameRender || state.fieldNameRender
      state.fieldIsSelected = fieldIsSelected || state.fieldIsSelected
      state.HeaderRender = HeaderRender || state.HeaderRender
      state.fieldSum = fieldSum || state.fieldSum
    },
    startDataSelection(state, action) {
      const {
        itemsSelected,
        checkDisableItems = initialState.checkDisableItems,
      } = action.payload

      state.loading = true
      state.items = initialState.items
      state.error = initialState.error
      state.fieldNameRender = initialState.fieldNameRender
      state.fieldIsSelected = initialState.fieldIsSelected
      state.checkDisableItems = checkDisableItems

      if (itemsSelected) {
        state.itemsSelected = itemsSelected

        const total = itemsSelected.reduce(
          (total: number, item: any) => total + item?.surface,
          0
        )

        state.sumFieldSelected = total
        state.numItemsSelected = itemsSelected?.length
      }
    },
    setErrorSelection(state) {
      state.loading = false
      state.error = true
    },
    getDataStart(state) {
      state.loading = true
    },
    replaceItem(state, action) {
      const { item, indexItem, indexSubItem } = action.payload
      const newState = { ...state }
      const newItems = [...state.items]
      const newItem = { ...state.items[indexItem] }
      const newSubItems = [...newItem.items]

      newSubItems[indexSubItem] = item
      newItem.items = newSubItems
      newItems[indexItem] = newItem

      newState.items = newItems

      if (item.selected) {
        newState.itemsSelected = [...state.itemsSelected, item]
        newState.numItemsSelected += 1

        // sum property field
        if (state.fieldSum) newState.sumFieldSelected += item[state.fieldSum]
      } else {
        newState.itemsSelected = state.itemsSelected.filter((itemSelected) => {
          return itemSelected._id !== item._id
        })
        newState.numItemsSelected -= 1

        // subtract property field
        if (state.fieldSum) newState.sumFieldSelected -= item[state.fieldSum]
      }

      return newState
    },
    disabledItems(state, action) {
      const { indexItem } = action.payload
      const isSelectedField = state.items[indexItem].items.some(
        (itemField: ItemField) => itemField.selected
      )

      if (isSelectedField !== state.checkDisableItems) {
        state.checkDisableItems = isSelectedField
        state.items = state.items.map((item: any, index: number) => {
          const disabled = index === indexItem ? item.disabled : isSelectedField
          return {
            ...item,
            disabled: disabled,
          }
        })
      }
    },
    checkedSelectedItem(state, action) {
      const { indexItem } = action.payload
      const items = state.items[indexItem].items.filter(
        (itemField: ItemField) => itemField.selected
      )
      const isSelectedAll = items.length === state.items[indexItem].items.length
      state.items[indexItem].selected = isSelectedAll
    },
    selectAllSubItems(state, action) {
      const { indexItem } = action.payload

      const selected = !state.items[indexItem].selected
      const currentItem = state.items[indexItem]

      currentItem.selected = selected
      currentItem.items = currentItem.items.map((item: any) => ({
        ...item,
        selected,
      }))

      const newItemsSelected = state.items.flatMap((item: any, key: number) => {
        if (key !== indexItem) {
          return [...item.items.filter(({ selected }: any) => selected)]
        }

        return [...currentItem.items.filter(({ selected }: any) => selected)]
      })

      state.numItemsSelected = newItemsSelected.length
      state.itemsSelected = newItemsSelected

      if (state.fieldSum) {
        const sumItems = newItemsSelected.reduce((sum: number, item: any) => {
          return state.fieldSum ? sum + item[state.fieldSum] : sum
        }, 0)

        state.sumFieldSelected = sumItems
      }
    },
  },
  extraReducers: () => {},
})

export const {
  resetDataSelection,
  setItemsSelection,
  startDataSelection,
  setErrorSelection,
  getDataStart,
  replaceItem,
  disabledItems,
  checkedSelectedItem,
  selectAllSubItems,
} = selectionPageSlice.actions

export const { reducer: selectionPageReducer } = selectionPageSlice
