import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useRef,
} from 'react'
import {
  View,
  Text,
  StyleSheet,
  ScrollView,
  Pressable,
  TextInput,
  Image,
  Platform,
} from 'react-native'
import { useFocusEffect } from '@react-navigation/native'

import { white } from '@common/styles/palette'
import convert from 'convert-units'
import { List, ActivityIndicator } from 'react-native-paper'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { uniqueId, round } from 'lodash'
import Fuse from 'fuse.js'
import { PropTypes } from 'prop-types'
import { green500 } from '@common/styles/palette'

import { CommonIcon } from '@modules/common/components'
import { CropContext } from '@contextState/crop'
import ButtonCustom from '@components/common/ButtonCustom'
import ModalError from '@components/common/v1/ModalError'
import ButtonPrimary from '@components/buttons/ButtonPrimary'
import useNetwork from '@utils/network'
import useModal from 'src/hooks/useModal'
import { LanguageContext } from '@contextState/language'
import {
  primary500,
  gray1,
  grayBackground,
  blackHighEmphasis,
  primary500Disabled,
  black,
  gray7,
  primary50,
  errorLight,
  placeholderColor,
  buttonDisabled,
} from '@styles/palette'
import { BackHandler } from 'react-native'
import { SURFACE_UNIT } from '@constants'
import { UnassociatedLicenseMenu } from '@modules/common/components'
import ModalConfirm from '@components/common/v1/ModalConfirm'
import { truncateSurface, TWO_DECIMAL_PLACE } from '@common/utils'
import RouterNames from '@constants/routerNames'

const DECIMAL_PLACE = TWO_DECIMAL_PLACE

const LicenseFieldsAdd = ({ navigation, route }) => {
  const { isReadOnly, cropId } = useRef(route.params).current

  const { t } = useContext(LanguageContext)
  const { currentCrop, setCurrentCropId } = useContext(CropContext)

  const { doRequest } = useNetwork()
  const {
    isModalVisible: isModalErrorVisible,
    setModalIsVisible: setModalErrorVisible,
  } = useModal()
  const {
    isModalVisible: isModalVisibleUnassociable,
    closeModal: closeModalUnassociable,
    toggleModal: toggleModalUnassociable,
  } = useModal()

  const [allEstablishments, setAllEstablishments] = useState([])
  const [establishments, setEstablishments] = useState([])
  const [allEstablishmentSelected, setAllEstablishmentSelected] =
    useState(false)
  const [lotsSelected, setLotsSelected] = useState([])
  const [hasSelected, setHasSelected] = useState(0)
  const [hasAvailable, setHasAvailable] = useState(0)
  const [hasMinAvailable, setHasMinAvailable] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [applyLicenseLoading, setApplyLicenseLoading] = useState(false)
  const [verifyAllEstablishments, setVerifyAllEstablishments] = useState({
    establishmentsSelected: [],
    newEstablishments: [],
  })
  const [areaUnit, setAreaUnit] = useState('')
  const [unAssociableLot, setUnAssociableLot] = useState(null)
  const [searchField, setSearchField] = useState({
    id: uniqueId('field_'),
    value: '',
    placeholder: t('VIEWS').LICENSE_LOTS_ADD.FIELD_1.NAME,
    onChangeValue: (value) => {
      setSearchField({
        ...searchField,
        value: value,
      })
    },
  })
  const [licenseSelected, selectLicense] = useState()

  /**
   * INIT FUNCTIONS
   */
  useFocusEffect(
    useCallback(() => {
      setCurrentCropId(cropId)

      fetchLicenseDetails()

      let backHandler

      if (Platform.OS !== 'web') {
        backHandler = BackHandler.addEventListener(
          'hardwareBackPress',
          onBackPress
        )
      }

      return () => backHandler?.remove()
    }, [])
  )

  useEffect(() => {
    if (!licenseSelected) {
      return
    }

    fetchEstablishment()
  }, [licenseSelected])

  /**
   * FETCH LICENSE DETAILS
   *
   */
  const fetchLicenseDetails = async () => {
    const response = await doRequest({
      method: 'GET',
      url: `licenses/${route.params.licenseId}?cropId=${route.params.cropId}`,
    })

    const data = response.data

    selectLicense(data)
  }

  /**
   * GO TO LICENSE DETAILS
   */
  const onBackPress = () => {
    navigation.navigate(RouterNames.LICENSES_DETAIL, {
      cropId: route.params.cropId,
      licenseId: route.params.licenseId,
    })
    return true
  }

  /**
   * LISTEN CHANGES OF SEARCH FIELD FOR FILTER ESTABLISHMENTS
   */
  useEffect(() => {
    if (!allEstablishments.length) {
      return
    }

    if (!searchField.value) {
      return setEstablishments(
        allEstablishments.map((element) =>
          isReadOnly
            ? {
                ...element,
                disabled: true,
              }
            : element
        )
      )
    }

    const options = {
      includeScore: false,
      keys: ['tag'],
    }

    const fuse = new Fuse(allEstablishments, options)

    const establishmentsFiltered = fuse
      .search(searchField.value)
      .map((element) =>
        isReadOnly
          ? {
              ...element.item,
              disabled: true,
            }
          : element.item
      )

    setEstablishments(establishmentsFiltered)
  }, [allEstablishments, searchField])

  /**
   * LISTEN WHEN ESTABLISHMENTS CHANGE
   */
  useEffect(() => {
    if (!establishments.length) {
      return
    }

    const lotsSelected = []
    const establishmentsSelected = []
    let establishmentsQuantity = 0
    let hasSelected = 0

    establishments.forEach((establishment) => {
      establishment.lots.forEach((lot) => {
        if (
          lot.selected ||
          (lot.licenseApplied && licenseSelected.hasSubLicenses)
        ) {
          lotsSelected.push(lot)

          hasSelected += lot.availableArea
        }
      })

      if (establishment.selected) {
        establishmentsSelected.push(establishment)
      }

      establishmentsQuantity++
    })

    setAllEstablishmentSelected(
      establishmentsQuantity &&
        establishmentsQuantity === establishmentsSelected.length
    )

    setLotsSelected(lotsSelected)

    setHasSelected(hasSelected)
  }, [establishments])

  /**
   * GET HAS AVAILABLE
   */
  const getHasAvailable = () => {
    const hasAvailableByLicense =
      licenseSelected.hectareLimit - licenseSelected.hectareUsedCounter

    if (licenseSelected.hectareLimitIdentifier === 0) {
      return hasAvailableByLicense
    }

    let hasAvailable = hasAvailableByLicense

    if (licenseSelected.hectareLimitIdentifier < hasAvailableByLicense) {
      hasAvailable =
        licenseSelected.hectareLimitIdentifier -
        licenseSelected.hectareUsedCounterByIdentifier
    }

    return hasAvailable
  }

  const fetchEstablishment = async () => {
    try {
      setIsLoading(true)
      const response = await doRequest({
        method: 'GET',
        url: `crops/${route.params.cropId}/establishments?licenseId=${route.params.licenseId}`,
      })
      setEstablishments(response.data)
      setAllEstablishments(response.data)
      verifyEstablishment(response.data)
      if (response?.data?.length) {
        setAreaUnit(response.data[0]?.areaUnit)
        let hectareAvailable = getHasAvailable()
        let hectareMinIdentifier = licenseSelected.hectareMinIdentifier
        if (response.data[0]?.areaUnit === SURFACE_UNIT.AC) {
          hectareAvailable = convert(hectareAvailable)
            .from(SURFACE_UNIT.HA)
            .to(SURFACE_UNIT.AC)
          hectareMinIdentifier = convert(hectareMinIdentifier)
            .from(SURFACE_UNIT.HA)
            .to(SURFACE_UNIT.AC)
        }
        setHasAvailable(hectareAvailable)
        setHasMinAvailable(hectareMinIdentifier)
      }
      setIsLoading(false)
    } catch (error) {
      console.error('Error al traer los establecimientos')
    }
  }

  /**
   * DISPLAY/UNDISPLAY ESTABLISHMENT
   *
   * @param {*} establishment
   */
  const displayEstablishmentToggle = async (establishment) => {
    const newEstablishments = establishments.map((element) => {
      if (element._id !== establishment._id) {
        return element
      }

      return {
        ...element,
        display: !element.display,
      }
    })

    setEstablishments(newEstablishments)
  }

  /**
   * SELECT/UNSELECT ESTABLISHMENT
   *
   * @param {*} establishment
   */
  const selectEstablishmentToggle = async (establishment) => {
    if (establishment.disabled) {
      return
    }

    const newEstablishments = establishments.map((element) => {
      if (element._id !== establishment._id) {
        return element
      }

      const newLots = element.lots.map((lotElement) => {
        return {
          ...lotElement,
          selected: !lotElement.disabled
            ? !element.selected
            : lotElement.selected,
        }
      })

      return {
        ...element,
        selected: !element.selected,
        lots: newLots,
      }
    })

    setEstablishments(newEstablishments)
  }

  const verifyEstablishment = async (arrayEstablishment = establishments) => {
    const establishmentsSelected = []
    const newEstablishments = arrayEstablishment.map((element) => {
      const newLots = element.lots.map((lotElement) => {
        return {
          ...lotElement,
          selected: !lotElement.disabled
            ? !allEstablishmentSelected
            : lotElement.selected,
        }
      })

      if (element.disabled) {
        return element
      }

      establishmentsSelected.push(element)

      return {
        ...element,
        selected: !allEstablishmentSelected,
        lots: newLots,
      }
    })
    setVerifyAllEstablishments({ establishmentsSelected, newEstablishments })
    return { establishmentsSelected, newEstablishments }
  }

  /**
   * SELECT/UNSELECT ALL ESTABLISHMENTS
   */
  const selectAllEstablishmentsToggle = (newEstablishments) => {
    setEstablishments(newEstablishments)
  }

  /**
   * SELECT/UNSELECT LOT
   *
   * @param {*} establishment
   * @param {*} lot
   */
  const selectLotToggle = async (establishment, lot) => {
    if (lot.disabled || establishment.disabled) {
      return
    }

    const newEstablishments = establishments.map((element) => {
      if (element._id !== establishment._id) {
        return element
      }

      const allLotSelected = []

      const newLots = element.lots.map((lotElement) => {
        if (lotElement._id !== lot._id) {
          if (lotElement.selected) {
            allLotSelected.push(lotElement)
          }

          return lotElement
        }

        if (!lotElement.selected) {
          allLotSelected.push(lotElement)
        }

        return {
          ...lotElement,
          selected: !lotElement.selected,
        }
      })

      return {
        ...element,
        selected:
          allLotSelected.length ===
          newLots.filter((lot) => !lot.disabled).length,
        lots: newLots,
      }
    })

    setEstablishments(newEstablishments)
  }

  /**
   * GET LOTS SELECTED QUANTITY BY ESTABLISHMENT
   *
   * @param {*} establishment
   *
   * @returns Number
   */
  const getLotsSelectedQuantityByEstablishment = ({ lots }) => {
    return lots.filter((element) => !element.disabled && element.selected)
      .length
  }

  /**
   * VERIFY APPLY LICENSE VALIDATION
   */
  const isApplyLicenseValid = () => {
    const hasAnyLotSelected = establishments.filter((element) => {
      return element.lots.some((item) => item.selected)
    }).length

    if (!hasAnyLotSelected) {
      return false
    }

    return isHasValid()
  }

  /**
   * VERIFY HAS VALIDATION
   */
  const isHasValid = () => {
    return (
      hasSelected !== 0 &&
      hasAvailable >= hasSelected &&
      hasMinAvailable <= hasSelected
    )
  }
  /**
   * VERIFY MIN HAS ALLOWED
   */
  const canApplyMinimumAllowed = () =>
    hasSelected > 0 && hasMinAvailable <= hasSelected

  /**
   * APPLY LICENSE
   */
  const applyLicense = async () => {
    if (!isApplyLicenseValid() || applyLicenseLoading) {
      return
    }

    if (licenseSelected.hasSubLicenses) {
      goToSummaryLicenseLots()

      return
    }

    setApplyLicenseLoading(true)

    const data = {
      cropId: route.params.cropId,
      lots: lotsSelected
        .filter((element) => !element.licenseApplied)
        .map((element) => element._id),
      companyIdentifier: currentCrop.identifier,
    }

    try {
      const response = await doRequest({
        method: 'POST',
        url: `licenses/${route.params.licenseId}/apply`,
        data,
        displayAlert: false,
      })

      setApplyLicenseLoading(false)

      if (response) {
        goToSummaryLicenseLots()
      } else {
        setModalErrorVisible(true)
      }
    } catch (error) {
      setModalErrorVisible(true)
    }
  }

  /**
   * GO TO LICENSES
   */
  const goToLicenses = () => {
    setModalErrorVisible(false)

    navigation.navigate(RouterNames.LICENSES_LIST, {
      cropId: route.params.cropId,
    })
  }

  /**
   * GO TO HOME
   */
  const goToHome = () => {
    setModalErrorVisible(false)
    navigation.navigate('CropList')
  }

  /**
   * GO TO SUMMARY LICENSE LOTS
   */
  const goToSummaryLicenseLots = () => {
    navigation.navigate(RouterNames.LICENSES_SUMMARY, {
      licenseId: route.params.licenseId,
      cropId: route.params.cropId,
      lots: lotsSelected,
      establishments,
      hasSelected,
      companyIdentifier: currentCrop.identifier,
      cropNameText: `${currentCrop.name} - ${currentCrop.company?.name}`,
    })
  }

  /**
   * GO TO SELECTED COMPANY
   */
  const goToSelectCompany = () => {
    navigation.navigate('subLicenseSelectCompanies', {
      lots: lotsSelected,
      lotsSelected: lotsSelected.length,
      has: String(round(hasSelected, 2)),
      licenseId: licenseSelected._id,
      cropId: route.params.cropId,
      companyIdentifier: currentCrop.identifier,
      cropNameText: `${currentCrop.name} - ${currentCrop.company?.name}`,
    })
  }

  const onPressUnassociated = (lot) => {
    toggleModalUnassociable()
    setUnAssociableLot(lot)
  }

  const confirmUnAssociate = async () => {
    const data = {
      cropId: currentCrop._id,
      lots: [unAssociableLot._id],
    }
    try {
      if (unAssociableLot) {
        await doRequest({
          method: 'DELETE',
          url: `licenses/${licenseSelected._id}/unapply`,
          version: 'v2',
          data,
        })
      }
      fetchEstablishment()
      closeModalUnassociable()
    } catch (error) {
      console.error('error confirm UnAssociable', error)
    }
  }

  const RenderMessageApplyLicense = () => {
    const canApplyLicense = isHasValid()
    const canApplyMinimum = canApplyMinimumAllowed()
    let MESSAGE = 'VIEWS.LICENSE_LOTS_ADD.'
    MESSAGE += canApplyLicense
      ? 'CAN_APPLY_LICENSE'
      : !canApplyMinimum
      ? 'INVALID_APPLY_MINIMUM_ALLOWED'
      : 'INVALID_APPLY_MAXIMUM_ALLOWED'

    if (!hasSelected > 0) {
      return (
        <View style={styles.containerTexts}>
          <Text style={styles.selectLotsText}>
            {t('VIEWS').LICENSE_LOTS_ADD.TEXT_2}
          </Text>
        </View>
      )
    }
    return (
      <View
        style={
          canApplyLicense ? styles.containerTexts : styles.containerTextsInvalid
        }
      >
        <Text style={styles.lotsAvailableText}>
          {`${t('VIEWS.LICENSE_LOTS_ADD.TEXT_2')}\n\n`}
          {t(MESSAGE, {
            hasSelected: truncateSurface(hasSelected, DECIMAL_PLACE),
            hasMinAvailable: truncateSurface(hasMinAvailable, DECIMAL_PLACE),
            hasAvailable: truncateSurface(hasAvailable, DECIMAL_PLACE),
            areaUnit,
          })}
        </Text>
      </View>
    )
  }

  if (isLoading) {
    return (
      <ActivityIndicator size='large' color={green500} style={styles.loader} />
    )
  }

  return (
    <SafeAreaProvider style={styles.safeAreaProvider}>
      <ScrollView style={styles.container}>
        <Text style={styles.titleLicense}>
          {`${currentCrop.name} - ${currentCrop.company?.name}`}
        </Text>

        <View style={styles.containerSearchField}>
          <TextInput
            style={styles.searchField}
            placeholder={searchField.placeholder}
            placeholderTextColor={placeholderColor}
            value={searchField.value}
            onChangeText={(text) => searchField.onChangeValue(text)}
            autoCorrect={false}
          />

          <CommonIcon
            name='SEARCH'
            size={24}
            color={blackHighEmphasis}
            style={styles.searchIcon}
          />
        </View>
        {!isReadOnly && <RenderMessageApplyLicense />}

        <Pressable
          style={[
            styles.accordionsHeader,
            verifyAllEstablishments.establishmentsSelected.length > 0 &&
            !isReadOnly
              ? {}
              : styles.opacity,
          ]}
          onPress={async () => {
            if (isReadOnly) {
              return
            }

            const verify = await verifyEstablishment()
            if (verify.establishmentsSelected.length > 0) {
              selectAllEstablishmentsToggle(verify.newEstablishments)
            }
          }}
        >
          <CommonIcon
            name={
              allEstablishmentSelected
                ? 'CHECKBOX-SELECTED'
                : 'CHECKBOX-UNSELECTED'
            }
            size={24}
            color={allEstablishmentSelected ? primary500 : blackHighEmphasis}
            style={styles.accordionsHeaderIcon}
          />

          <View style={styles.accordionsHeaderTextContainer}>
            <Text style={styles.accordionsHeaderText}>
              {t('VIEWS').LICENSE_LOTS_ADD.TEXT_5}
            </Text>
          </View>
        </Pressable>

        <View style={styles.containerContent}>
          {establishments.map((establishment, key) => {
            const lotsSelectedQuantity =
              getLotsSelectedQuantityByEstablishment(establishment)

            const establishmentTitle =
              establishment.tag +
              (lotsSelectedQuantity
                ? `(${lotsSelectedQuantity} ${
                    t('VIEWS').LICENSE_LOTS_ADD.TEXT_8
                  })`
                : '')

            return (
              <View key={key}>
                <View
                  style={
                    !establishment.disabled
                      ? styles.containerAccordion
                      : styles.containerDisabledAccordion
                  }
                >
                  <CommonIcon
                    name={
                      establishment.selected
                        ? 'CHECKBOX-SELECTED'
                        : 'CHECKBOX-UNSELECTED'
                    }
                    size={24}
                    color={
                      establishment.selected ? primary500 : blackHighEmphasis
                    }
                    style={styles.accordionIcon}
                    onPress={() => {
                      selectEstablishmentToggle(establishment)
                    }}
                  />

                  <View style={styles.subContainerAccordion}>
                    <List.Accordion
                      title={establishmentTitle}
                      style={styles.accordion}
                      titleStyle={
                        !establishment.display
                          ? styles.accordionTitle
                          : styles.accordionTitleSelected
                      }
                      expanded={establishment.display}
                      onPress={() => displayEstablishmentToggle(establishment)}
                    ></List.Accordion>
                  </View>
                </View>

                {establishment.display && (
                  <View style={styles.containerLots}>
                    {establishment.lots
                      .filter((lot) => !lot.disabled)
                      .map((lot, lotKey) => (
                        <View key={lotKey} style={styles.containerLot}>
                          <Pressable
                            style={[
                              styles.subContainerLot,
                              isReadOnly ? styles.subContainerDisabledLot : {},
                            ]}
                            onPress={() => selectLotToggle(establishment, lot)}
                          >
                            <CommonIcon
                              name={
                                lot.selected
                                  ? 'CHECKBOX-SELECTED'
                                  : 'CHECKBOX-UNSELECTED'
                              }
                              size={24}
                              color={
                                lot.selected ? primary500 : blackHighEmphasis
                              }
                              style={styles.lotIcon}
                            />

                            <View style={styles.lotTexts}>
                              <Text
                                style={styles.lotName}
                              >{`${lot.name}`}</Text>

                              <Text style={styles.lotSurface}>
                                {`${truncateSurface(
                                  lot.availableArea,
                                  DECIMAL_PLACE
                                )} ${areaUnit}`}
                              </Text>

                              <Text style={styles.lotLocation}>
                                {`${lot.cityName ?? ''}, ${
                                  lot.provinceName ?? ''
                                }`}
                              </Text>

                              {lot.overlappingRange && (
                                <Text
                                  style={[
                                    styles.lotOverlappingRange,
                                    {
                                      backgroundColor:
                                        lot.overlappingRange.style
                                          .backgroundColor,
                                      color:
                                        lot.overlappingRange.style.textColor,
                                    },
                                  ]}
                                >
                                  {t('VIEWS.LICENSE_LOTS_ADD.TEXT_17', {
                                    overlappingPercentage:
                                      lot.percentageOfOverlapping,
                                  })}
                                </Text>
                              )}
                            </View>

                            <View style={styles.lotImageContainer}>
                              <Image
                                source={{
                                  uri: lot.imageUrl,
                                }}
                                style={styles.lotImage}
                              />
                            </View>
                          </Pressable>
                        </View>
                      ))}

                    {establishment.lots.find(
                      (lot) => lot.disabled && lot.licenseApplied
                    ) && (
                      <>
                        {establishment.lots
                          .filter((lot) => lot.disabled && lot.licenseApplied)
                          .map((lot, lotKey) => (
                            <View key={lotKey} style={styles.containerLot}>
                              <View style={styles.subContainerDisabledLot}>
                                <CommonIcon
                                  name={'CHECKBOX-SELECTED'}
                                  size={24}
                                  color={primary500}
                                  style={styles.lotIcon}
                                />

                                <View style={styles.lotTexts}>
                                  <Text style={styles.lotName}>{lot.name}</Text>

                                  <Text style={styles.lotSurface}>
                                    {`${truncateSurface(
                                      lot.availableArea,
                                      DECIMAL_PLACE
                                    )} ${areaUnit}`}
                                  </Text>

                                  <Text style={styles.lotLocation}>
                                    {`${lot.cityName ?? ''}, ${
                                      lot.provinceName ?? ''
                                    }`}
                                  </Text>

                                  {lot.overlappingRange && (
                                    <Text
                                      style={[
                                        styles.lotOverlappingRange,
                                        {
                                          backgroundColor:
                                            lot.overlappingRange.style
                                              .backgroundColor,
                                          color:
                                            lot.overlappingRange.style
                                              .textColor,
                                        },
                                      ]}
                                    >
                                      {t('VIEWS.LICENSE_LOTS_ADD.TEXT_17', {
                                        overlappingPercentage:
                                          lot.percentageOfOverlapping,
                                      })}
                                    </Text>
                                  )}
                                </View>

                                <View
                                  style={styles.lotImageContainerWithoutMargin}
                                >
                                  <Image
                                    source={{
                                      uri: lot.imageUrl,
                                    }}
                                    style={styles.lotImage}
                                  />
                                </View>
                                <View style={styles.contentButtonMenu}>
                                  {lot.canRemoverLicenseApplied && (
                                    <UnassociatedLicenseMenu
                                      onPress={() => onPressUnassociated(lot)}
                                    />
                                  )}
                                </View>
                              </View>
                            </View>
                          ))}
                      </>
                    )}

                    {establishment.lots.find(
                      (lot) => lot.disabled && !lot.licenseApplied
                    ) && (
                      <>
                        <View style={styles.disabledLotsHeader}>
                          <Text
                            style={styles.disabledLotsHeaderOverlappingText}
                          >
                            {t('VIEWS').LICENSE_LOTS_ADD.TEXT_18}
                          </Text>
                        </View>

                        {establishment.lots
                          .filter((lot) => lot.disabled && !lot.licenseApplied)
                          .map((lot, lotKey) => (
                            <View key={lotKey} style={styles.containerLot}>
                              <View style={styles.subContainerDisabledLot}>
                                <CommonIcon
                                  name={
                                    lot.selected
                                      ? 'CHECKBOX-SELECTED'
                                      : 'CHECKBOX-UNSELECTED'
                                  }
                                  size={24}
                                  color={
                                    lot.selected
                                      ? primary500
                                      : blackHighEmphasis
                                  }
                                  style={styles.lotIcon}
                                  onPress={() => {
                                    selectLotToggle(establishment, lot)
                                  }}
                                />

                                <View style={styles.lotTexts}>
                                  <Text style={styles.lotName}>{lot.name}</Text>

                                  <Text style={styles.lotSurface}>
                                    {`${truncateSurface(
                                      lot.surface,
                                      DECIMAL_PLACE
                                    )} ${lot.areaUnit}`}
                                  </Text>

                                  <Text style={styles.lotLocation}>
                                    {`${lot.cityName ?? ''}, ${
                                      lot.provinceName ?? ''
                                    }`}
                                  </Text>

                                  {lot.overlappingRange && (
                                    <Text
                                      style={[
                                        styles.lotOverlappingRange,
                                        {
                                          backgroundColor:
                                            lot.overlappingRange.style
                                              .backgroundColor,
                                          color:
                                            lot.overlappingRange.style
                                              .textColor,
                                        },
                                      ]}
                                    >
                                      {t('VIEWS.LICENSE_LOTS_ADD.TEXT_17', {
                                        overlappingPercentage:
                                          lot.percentageOfOverlapping,
                                      })}
                                    </Text>
                                  )}
                                </View>

                                <View style={styles.lotImageContainer}>
                                  <Image
                                    source={{
                                      uri: lot.imageUrl,
                                    }}
                                    style={styles.lotImage}
                                  />
                                </View>
                              </View>
                            </View>
                          ))}
                      </>
                    )}
                  </View>
                )}
              </View>
            )
          })}
        </View>
      </ScrollView>

      {!isReadOnly && (
        <ButtonCustom
          onPress={applyLicense}
          styles={isApplyLicenseValid() ? styles.button : styles.buttonDisabled}
        >
          {!applyLicenseLoading ? (
            <Text
              style={
                isApplyLicenseValid() ? styles.textEnabled : styles.textDisabled
              }
            >
              {t('VIEWS.LICENSE_LOTS_ADD.TEXT_9', {
                lotsQuantity: lotsSelected.length
                  ? `${lotsSelected.length} `
                  : '',
              })}
            </Text>
          ) : (
            <ActivityIndicator size='small' color={white} />
          )}
        </ButtonCustom>
      )}

      {!isReadOnly &&
        licenseSelected.hasSubLicenses &&
        licenseSelected.isApplied && (
          <ButtonCustom
            mode='outlined'
            onPress={goToSelectCompany}
            styles={[styles.buttonOutlined]}
            labelStyle={styles.labelButtonOutlined}
          >
            {t('VIEWS').LICENSE_LOTS_ADD.TEXT_19}
          </ButtonCustom>
        )}

      <ModalError
        visible={isModalErrorVisible}
        title={t('VIEWS').LICENSE_LOTS_ADD.TEXT_15}
        description={t('VIEWS').LICENSE_LOTS_ADD.TEXT_16}
      >
        <View style={styles.buttonsContainerError}>
          <ButtonPrimary
            text={t('VIEWS').LICENSE_LOTS_ADD.TEXT_13}
            onPress={goToLicenses}
            colorText={blackHighEmphasis}
          />

          <ButtonPrimary
            marginTop={20}
            text={t('VIEWS').LICENSE_LOTS_ADD.TEXT_14}
            backgroundColor='transparent'
            colorText={white}
            fontSize={14}
            onPress={goToHome}
          />
        </View>
      </ModalError>

      <ModalConfirm
        visible={isModalVisibleUnassociable}
        onClose={closeModalUnassociable}
        onConfirm={confirmUnAssociate}
        title={t('VIEWS.LICENSE_LOTS_ADD.MODAL_UNASSOCIATE.TITLE')}
        contentText={t('VIEWS.LICENSE_LOTS_ADD.MODAL_UNASSOCIATE.INFO')}
        confirmText={t('VIEWS.LICENSE_LOTS_ADD.MODAL_UNASSOCIATE.CONFIRM')}
        cancelText={t('VIEWS.LICENSE_LOTS_ADD.MODAL_UNASSOCIATE.CANCEL')}
      />
    </SafeAreaProvider>
  )
}

const styles = StyleSheet.create({
  safeAreaProvider: {
    backgroundColor: white,
  },
  container: {
    flex: 1,
  },
  loader: {
    marginTop: 100,
  },
  titleLicense: {
    fontSize: 16,
    fontWeight: '500',
    paddingVertical: 16,
    paddingHorizontal: 20,
  },
  containerSearchField: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 16,
    marginRight: 16,
    shadowColor: black,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
  searchField: {
    flex: 1,
    height: 48,
    backgroundColor: white,
    color: blackHighEmphasis,
    borderRadius: 2,
    paddingTop: 2,
    paddingRight: 16,
    paddingBottom: 2,
    paddingLeft: 16,
    fontSize: 16,
    fontWeight: '500',
  },
  searchIcon: {
    marginLeft: 10,
    marginRight: 10,
    color: gray7,
  },
  containerTexts: {
    flexDirection: 'column',
    marginLeft: 16,
    marginRight: 16,
    marginBottom: 15,
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
    padding: 16,
    backgroundColor: primary50,
  },
  containerTextsInvalid: {
    flexDirection: 'column',
    marginLeft: 16,
    marginRight: 16,
    marginBottom: 15,
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
    padding: 16,
    backgroundColor: errorLight,
  },
  selectLotsText: {
    fontSize: 14,
    fontWeight: '700',
    color: gray7,
  },
  lotsAvailableText: {
    fontSize: 14,
    fontWeight: '700',
    color: gray7,
  },
  accordionsHeader: {
    flexDirection: 'row',
    alignContent: 'space-between',
    width: '100%',
  },
  accordionsHeaderIcon: {
    marginLeft: 25,
    marginRight: 25,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  accordionsHeaderTextContainer: {
    flex: 1,
    paddingTop: 18,
    paddingBottom: 18,
    borderBottomWidth: 1,
    borderBottomColor: gray1,
  },
  accordionsHeaderText: {
    fontSize: 16,
    fontWeight: '500',
  },
  containerAccordion: {
    flexDirection: 'row',
    alignContent: 'space-between',
    width: '100%',
  },
  containerDisabledAccordion: {
    flexDirection: 'row',
    alignContent: 'space-between',
    width: '100%',
    opacity: 0.5,
  },
  accordionIcon: {
    marginLeft: 25,
    marginRight: 25,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  subContainerAccordion: {
    flex: 1,
  },
  accordion: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 0,
    paddingRight: 16,
    backgroundColor: white,
    borderBottomWidth: 1,
    borderBottomColor: gray1,
  },
  accordionTitle: {
    color: black,
    fontWeight: '500',
    marginLeft: -8,
  },
  accordionTitleSelected: {
    color: primary500,
    fontWeight: '500',
    marginLeft: -8,
  },
  containerLot: {
    backgroundColor: grayBackground,
    paddingLeft: 5,
    paddingRight: 5,
  },
  subContainerLot: {
    flexDirection: 'row',
    paddingTop: 10,
    paddingBottom: 10,
    borderBottomWidth: 1,
    borderBottomColor: gray1,
  },
  lotIcon: {
    marginLeft: 30,
    marginRight: 30,
  },
  lotTexts: {
    flex: 1,
  },
  lotName: {
    fontSize: 16,
    fontWeight: '500',
    marginBottom: 5,
  },
  lotSurface: {
    fontSize: 14,
    color: blackHighEmphasis,
    marginBottom: 5,
  },
  lotLocation: {
    fontSize: 14,
    color: blackHighEmphasis,
  },
  lotOverlappingRange: {
    fontSize: 10,
    fontWeight: '500',
    marginTop: 7.5,
  },
  lotImageContainer: {
    marginLeft: 'auto',
    marginRight: 20,
  },
  lotImageContainerWithoutMargin: {
    marginLeft: 'auto',
  },
  lotImage: {
    height: 60,
    width: 100,
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  disabledLotsHeader: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: grayBackground,
  },
  disabledLotsHeaderBoldText: {
    fontSize: 16,
    fontWeight: '500',
  },
  disabledLotsHeaderText: {
    fontSize: 16,
    fontStyle: 'italic',
    marginTop: 5,
  },
  disabledLotsHeaderOverlappingText: {
    fontSize: 16,
  },
  subContainerDisabledLot: {
    flexDirection: 'row',
    paddingTop: 10,
    paddingBottom: 10,
    borderBottomWidth: 1,
    borderBottomColor: gray1,
    opacity: 0.5,
  },
  button: {
    marginTop: 30,
    marginBottom: 15,
    marginLeft: 30,
    marginRight: 30,
    backgroundColor: primary500,
  },
  buttonDisabled: {
    marginTop: 30,
    marginBottom: 15,
    marginLeft: 30,
    marginRight: 30,
    backgroundColor: buttonDisabled,
  },
  buttonOutlined: {
    marginLeft: 30,
    marginRight: 30,
    marginBottom: 20,
    borderColor: primary500,
  },
  buttonOutlinedDisabled: {
    borderColor: primary500Disabled,
  },
  labelButtonOutlined: {
    color: primary500,
  },
  labelButtonOutlinedDisabled: {
    color: primary500Disabled,
  },
  buttonsContainerError: {
    marginTop: 10,
    width: 300,
  },
  opacity: {
    opacity: 0.5,
  },
  textEnabled: {
    color: '#fff',
  },
  textDisabled: {
    color: blackHighEmphasis,
  },
  contentButtonMenu: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 20,
  },
})

LicenseFieldsAdd.propTypes = {
  navigation: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
}

export default LicenseFieldsAdd
