import React, { useState, useContext, useCallback, useEffect } from 'react'
import { Text, StyleSheet, View, BackHandler, Platform } from 'react-native'
import { useFocusEffect } from '@react-navigation/native'
import { ActivityIndicator } from 'react-native-paper'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { PropTypes } from 'prop-types'
import moment from 'moment'
import { round } from 'lodash'

import { SustainabilityIcon } from '@modules/common/components'
import { convertNumbertoString } from '@utils/number'
import ButtonCustom from '@components/common/ButtonCustom'
import { LanguageContext } from '@contextState/language'
import { CropContext } from '@contextState/crop'
import {
  primary500,
  blackHighEmphasis,
  blackMediumEmphasis,
  grayBackground,
  white,
  primary500Disabled,
} from '@styles/palette'
import ChartLots from '@components/licenses/v1/ChartLots'
import useNetwork from '@utils/network'
import { useSelector } from 'react-redux'
import RouterNames from '@constants/routerNames'

const SummaryLandscapeSustainability = ({ navigation, route }) => {
  const { isConnected } = useSelector((state) => state.connectionReducer)
  const { t } = useContext(LanguageContext)
  const { currentCrop } = useContext(CropContext)
  const { doRequest } = useNetwork()
  const [isLoading, setIsloading] = useState(true)
  const [cropName, setCropName] = useState(null)
  const [dataLicense, setDataLicense] = useState(null)

  /**
   * INIT FUNCTIONS
   */
  useFocusEffect(
    useCallback(() => {
      setIsloading(true)
      fetchSummaryLandscapeSustainability()

      let backHandler

      if (Platform.OS !== 'web') {
        backHandler = BackHandler.addEventListener(
          'hardwareBackPress',
          onBackPress
        )
      }

      return () => backHandler?.remove()
    }, [])
  )

  /*
  CROP NAME
  */
  useEffect(() => {
    setCropName(
      `${
        currentCrop.cropType.keyLabel || currentCrop.cropType.codeLabel
      } - ${moment(currentCrop.dateCrop).format('MM/YY')} a ${moment(
        currentCrop.dateHarvest
      ).format('MM/YY')} - ${currentCrop.company.name}`
    )
  }, [currentCrop])

  /**
   * FETCH SUMMARY LANDSCAPE SUSTAINABILITY
   *
   */
  const fetchSummaryLandscapeSustainability = async () => {
    let data = []
    if (isConnected) {
      const response = await doRequest({
        method: 'GET',
        url: `crops/${route.params.cropId}/establishments/summary-by-sustainability?licenseId=${route.params.licenseId}`,
      })
      data = response.data
    }
    setDataLicense(data)
    setIsloading(false)
  }

  /**
   * GO TO LICENSE DETAILS
   */
  const onBackPress = () => {
    navigation.navigate(RouterNames.LICENSES_DETAIL, {
      cropId: route.params.cropId,
      licenseId: route.params.licenseId,
    })
    return true
  }

  /**
   * GO TO LICENSE LOTS ADD
   */
  const goToLicenseLotsAdd = () => {
    navigation.navigate(RouterNames.LICENSES_FIELDS_ADD, {
      cropId: route.params.cropId,
      licenseId: route.params.licenseId,
    })
  }

  return (
    <SafeAreaProvider style={styles.safeAreaProvider}>
      {!isLoading ? (
        <>
          <View style={styles.container}>
            <Text style={[styles.titleStyle, styles.titleLicense]}>
              {cropName}
            </Text>
            <Text style={[styles.titleStyle, styles.subtitleLicense]}>
              {t('VIEWS.SUMMARY_LANDSCAPE_SUSTAINABILITY.TEXT_4', {
                lots:
                  currentCrop.lots?.length ||
                  currentCrop.establishments?.length ||
                  0,
                has: convertNumbertoString(round(dataLicense.surfaceTotal, 2)),
                areaUnit: dataLicense?.areaUnit ?? '',
              })}
            </Text>

            <View style={{ justifyContent: 'center', flex: 1 }}>
              <View>
                <SustainabilityIcon
                  name={'LANDSCAPE-SUSTAINABILITY'}
                  size={140}
                  color={primary500}
                  style={styles.icon}
                />
                <Text style={styles.textSustainability}>
                  {t('VIEWS').SUMMARY_LANDSCAPE_SUSTAINABILITY.TEXT_1}
                </Text>
              </View>
              <View>
                <ChartLots
                  percent={dataLicense.percentAvailable}
                  lots={dataLicense.availableTotal}
                  total={dataLicense.surfaceTotal}
                  areaUnit={dataLicense?.areaUnit ?? ''}
                />
              </View>
            </View>
          </View>
          <View style={styles.containerButton}>
            <ButtonCustom
              disabled={!dataLicense.percentAvailable}
              onPress={goToLicenseLotsAdd}
              styles={[
                styles.button,
                dataLicense.percentAvailable
                  ? styles.buttonActive
                  : styles.buttonDisabled,
              ]}
            >
              <Text>{t('VIEWS').SUMMARY_LANDSCAPE_SUSTAINABILITY.TEXT_3}</Text>
            </ButtonCustom>
          </View>
        </>
      ) : (
        <ActivityIndicator
          size='large'
          color={primary500}
          style={styles.loader}
        />
      )}
    </SafeAreaProvider>
  )
}

const styles = StyleSheet.create({
  safeAreaProvider: {
    backgroundColor: grayBackground,
  },
  container: {
    flex: 1,
  },
  loader: {
    marginTop: 100,
  },
  titleLicense: {
    color: blackHighEmphasis,
  },
  subtitleLicense: {
    color: blackMediumEmphasis,
  },
  titleStyle: {
    fontSize: 16,
    fontWeight: 'bold',
    paddingVertical: 16,
    paddingHorizontal: 20,
    backgroundColor: white,
  },
  button: {
    marginTop: 30,
    marginBottom: 20,
    marginLeft: 30,
    marginRight: 30,
  },
  buttonActive: {
    backgroundColor: primary500,
  },
  buttonDisabled: {
    backgroundColor: primary500Disabled,
  },
  icon: {
    width: 140,
    height: 140,
    alignSelf: 'center',
  },
  textSustainability: {
    fontSize: 24,
    color: blackHighEmphasis,
    fontWeight: 'bold',
    paddingVertical: 20,
    textAlign: 'center',
  },
  containerButton: {
    backgroundColor: white,
  },
})

SummaryLandscapeSustainability.propTypes = {
  navigation: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
}

export default SummaryLandscapeSustainability
