export interface IField {
  name: string
  surface: number
  areaUnit: string
}

export interface IFarm {
  name: string
  surface: number
  areaUnit: string
  fields: IField[]
}

export enum EVerificationStatus {
  VALID = 'VALID',
  INVALID = 'INVALID',
}

export interface ISubtypeActivity {
  activityType: string
  label: string
  value: string
}

export interface IActivityCard {
  condition: string
  status: string
  activityTypeKey: string
  activityTypeName: string
  farmsQuantity: number
  fieldsQuantity: number
  farms: IFarm[]
  percentageRealized: number
  pendingSignsQuantity: number
  verificationStatus: EVerificationStatus
  activitySurface: number
  areaUnit: string
  subActivityTypeName: string
  eiq: number
  pay: number
  payUnitName: string
  estimatedPay: number
  estimatedPayUnitName: string
  onGoToActivity: () => void
  subTypeActivity?: ISubtypeActivity
  waterConsumption?: number
  typeAgreementName?: string
  harvestAction?: any
}
