import { FC, ReactElement, useContext } from 'react'
import { StyleSheet, View, Text, Animated, Pressable } from 'react-native'

import { CommonIcon } from '@modules/common/components'
import { LanguageContext } from '@contextState/language'
import { ActivityIcon } from '@modules/common/components'
import { SIZES } from '@modules/common/components/icons/interfaces'
import ChipCommon from '@modules/common/components/chips/ChipCommon'
import Accordion from '@components/common/v2/AccordionItem'
import { Card } from '@common/components'
import ButtonDefault from '@common/components/buttons/ButtonDefault'
import {
  black,
  gray500,
  gray600,
  gray800,
  green100,
  green500,
  green600,
  yellow200,
  yellow700,
} from '@modules/common/styles/palette'
import { ACTIVITY_CONDITIONS, getUnitsLongitude } from '@modules/common/utils'
import { IActivityCard, EVerificationStatus } from './interfaces'
import { useAuth } from '@common/hooks'

export const ActivityCard: FC<IActivityCard> = ({
  condition,
  status,
  activityTypeKey,
  activityTypeName,
  farmsQuantity,
  fieldsQuantity,
  farms,
  percentageRealized,
  pendingSignsQuantity,
  verificationStatus,
  activitySurface,
  areaUnit,
  subActivityTypeName,
  eiq,
  pay,
  payUnitName,
  estimatedPay,
  estimatedPayUnitName,
  onGoToActivity,
  subTypeActivity,
  waterConsumption,
  typeAgreementName,
  harvestAction,
}): ReactElement => {
  const { t, i18n }: any = useContext(LanguageContext)
  const { config } = useAuth()
  const unitMeasureSystem = config.companySelected?.unitMeasureSystem
  // @ts-ignore
  const unitsLongitude = getUnitsLongitude(unitMeasureSystem, i18n?.locale)

  const AccordionHeader: FC<any> = ({ arrowAngle, onPress }): ReactElement => {
    const farmsFieldsLabel = `${farmsQuantity} ${
      farmsQuantity > 1
        ? t('VIEWS.ACTIVITY_LIST.COMPONENTS.ACTIVITY_CARD.FARMS')
        : t('VIEWS.ACTIVITY_LIST.COMPONENTS.ACTIVITY_CARD.FARM')
    }, ${fieldsQuantity} ${
      fieldsQuantity > 1
        ? t('VIEWS.ACTIVITY_LIST.COMPONENTS.ACTIVITY_CARD.FIELDS')
        : t('VIEWS.ACTIVITY_LIST.COMPONENTS.ACTIVITY_CARD.FIELD')
    }`

    const percentageRealizedLabel =
      percentageRealized !== undefined
        ? t(
            'VIEWS.ACTIVITY_LIST.COMPONENTS.ACTIVITY_CARD.PERCENTAGE_REALIZED',
            {
              percentageRealized,
            }
          )
        : undefined

    const pendingSignsQuantityLabel = pendingSignsQuantity
      ? pendingSignsQuantity > 1
        ? t('VIEWS.ACTIVITY_LIST.COMPONENTS.ACTIVITY_CARD.PENDING_SIGNS', {
            pendingSignsQuantity,
          })
        : t('VIEWS.ACTIVITY_LIST.COMPONENTS.ACTIVITY_CARD.PENDING_SIGN', {
            pendingSignsQuantity,
          })
      : undefined

    const verificationStatusValidLabel =
      verificationStatus === EVerificationStatus.VALID
        ? t(
            'VIEWS.ACTIVITY_LIST.COMPONENTS.ACTIVITY_CARD.VERIFICATION_STATUS_VALID'
          )
        : undefined

    const verificationStatusInvalidLabel =
      verificationStatus === EVerificationStatus.INVALID
        ? t(
            'VIEWS.ACTIVITY_LIST.COMPONENTS.ACTIVITY_CARD.VERIFICATION_STATUS_INVALID'
          )
        : undefined

    const iconColor =
      condition === ACTIVITY_CONDITIONS.DRAFT.key ||
      condition === ACTIVITY_CONDITIONS.FINISHED.key
        ? gray500
        : black

    const opacityStyle =
      condition === ACTIVITY_CONDITIONS.DRAFT.key ||
      condition === ACTIVITY_CONDITIONS.FINISHED.key
        ? styles.textOpacity
        : {}

    const finishedStyle =
      condition === ACTIVITY_CONDITIONS.FINISHED.key ? styles.textFinished : {}

    return (
      <Pressable onPress={onPress}>
        <View style={styles.accordionHeaderContainer}>
          <View style={styles.iconContainer}>
            <ActivityIcon
              name={activityTypeKey}
              size={SIZES.MEDIUM}
              color={iconColor}
            />
          </View>

          <View style={styles.headerTextContainer}>
            <Text style={[styles.activityTypeName, opacityStyle]}>
              {activityTypeName} {typeAgreementName && `- ${typeAgreementName}`}
            </Text>

            <Text style={[styles.farmsFieldsLabel, opacityStyle]}>
              {farmsFieldsLabel}
            </Text>

            <Text style={[styles.statusLabel, finishedStyle]}>{status}</Text>

            <View style={styles.headerChipsContainer}>
              {percentageRealizedLabel && (
                <ChipCommon
                  text={percentageRealizedLabel}
                  backgroundColor={green100}
                  textColor={green600}
                  textTransform={'none'}
                  widthChips={0}
                  extraStyle={{
                    marginRight: 4,
                  }}
                />
              )}

              {pendingSignsQuantityLabel && (
                <ChipCommon
                  text={pendingSignsQuantityLabel}
                  backgroundColor={yellow200}
                  textColor={yellow700}
                  textTransform={'none'}
                  widthChips={0}
                  extraStyle={{
                    marginRight: 4,
                  }}
                />
              )}

              {verificationStatusValidLabel && (
                <ChipCommon
                  text={verificationStatusValidLabel}
                  backgroundColor={green100}
                  textColor={green600}
                  textTransform={'none'}
                  widthChips={0}
                  extraStyle={{
                    marginRight: 4,
                  }}
                />
              )}

              {verificationStatusInvalidLabel && (
                <ChipCommon
                  text={verificationStatusInvalidLabel}
                  backgroundColor={yellow200}
                  textColor={yellow700}
                  textTransform={'none'}
                  widthChips={0}
                  extraStyle={{
                    marginRight: 4,
                  }}
                />
              )}
            </View>
          </View>

          <View>
            <Animated.View style={{ transform: [{ rotateZ: arrowAngle }] }}>
              <CommonIcon
                name='CHEVRON-DOWN'
                size={SIZES.MEDIUM}
                color={black}
              />
            </Animated.View>
          </View>
        </View>
      </Pressable>
    )
  }

  const AccordionBody: FC<any> = ({
    surface,
    areaUnit,
    eiq,
    pay,
    payUnitName,
    estimatedPay,
    estimatedPayUnitName,
    farms,
    subTypeActivity,
    waterConsumption,
  }): ReactElement => {
    const surfaceLabel = surface
      ? t('VIEWS.ACTIVITY_LIST.COMPONENTS.ACTIVITY_CARD.SURFACE', {
          surface,
          areaUnit,
        })
      : undefined

    const eiqLabel =
      eiq !== undefined
        ? t('VIEWS.ACTIVITY_LIST.COMPONENTS.ACTIVITY_CARD.EIQ', {
            eiq,
          })
        : undefined

    const payLabel = pay
      ? t('VIEWS.ACTIVITY_LIST.COMPONENTS.ACTIVITY_CARD.YIELD', {
          pay,
          unitPay: payUnitName,
        })
      : undefined

    const estimatedPayLabel = estimatedPay
      ? t('VIEWS.ACTIVITY_LIST.COMPONENTS.ACTIVITY_CARD.ESTIMATED_YIELD', {
          pay: estimatedPay,
          unitPay: estimatedPayUnitName,
        })
      : undefined

    const labelWaterConsumption = waterConsumption
      ? `${t(
          'VIEWS.ACTIVITY_LIST.COMPONENTS.ACTIVITY_CARD.WATER_CONSUMPTION'
        )} ${waterConsumption} ${unitsLongitude.UNIT_2}`
      : undefined

    return (
      <View style={styles.accordionBodyContainer}>
        {subTypeActivity?.label && (
          <Text style={styles.subActivityTypeLabel}>
            {subTypeActivity.label}
            {harvestAction?.codeLabel ? ` - ${harvestAction.codeLabel}` : ''}
          </Text>
        )}

        {labelWaterConsumption && (
          <Text style={styles.payLabel}>{labelWaterConsumption}</Text>
        )}

        {surfaceLabel && (
          <Text style={styles.surfaceLabel}>{surfaceLabel}</Text>
        )}

        {eiqLabel && <Text style={styles.eiqLabel}>{eiqLabel}</Text>}

        {payLabel && <Text style={styles.payLabel}>{payLabel}</Text>}

        {estimatedPayLabel && (
          <Text style={styles.estimatedPayLabel}>{estimatedPayLabel}</Text>
        )}

        {farms.map(({ name, surface, areaUnit, fields }: any, key: number) => {
          const farmKey = `FARM_${key}`
          const farmNameLabel = `${name} - ${surface} ${areaUnit}`

          return (
            <View key={farmKey} style={styles.farmContainer}>
              <Text style={styles.farmNameLabel}>{farmNameLabel}</Text>

              {fields.map(({ name, surface, areaUnit }: any, key: number) => {
                const fieldKey = `FARM_${key}`
                const fieldNameLabel = `${name} - ${surface} ${areaUnit}`

                return (
                  <View key={fieldKey}>
                    <Text style={styles.fieldNameLabel}>{fieldNameLabel}</Text>
                  </View>
                )
              })}
            </View>
          )
        })}

        <ButtonDefault
          typeButton={'primary'}
          text={t('VIEWS.ACTIVITY_LIST.COMPONENTS.ACTIVITY_CARD.BUTTON_TEXT')}
          onPress={onGoToActivity}
          marginTop={17}
        />
      </View>
    )
  }

  return (
    <Card style={styles.container}>
      <Accordion
        ContentHeader={AccordionHeader}
        extraBodyStyle={styles.accordion}
      >
        <AccordionBody
          surface={activitySurface}
          areaUnit={areaUnit}
          activityTypeName={activityTypeName}
          subActivityTypeName={subActivityTypeName}
          eiq={eiq}
          pay={pay}
          payUnitName={payUnitName}
          estimatedPay={estimatedPay}
          estimatedPayUnitName={estimatedPayUnitName}
          farms={farms}
          subTypeActivity={subTypeActivity}
          waterConsumption={waterConsumption}
        />
      </Accordion>
    </Card>
  )
}

const styles = StyleSheet.create({
  container: {
    marginTop: 8,
  },
  accordion: {
    paddingTop: 0,
  },
  accordionHeaderContainer: {
    flex: 1,
    flexDirection: 'row',
    paddingVertical: 22,
    paddingHorizontal: 18,
  },
  iconContainer: {
    marginTop: 4,
    marginRight: 14,
  },
  headerTextContainer: {
    flex: 1,
    flexDirection: 'column',
  },
  activityTypeName: {
    fontSize: 14,
    fontWeight: '600',
    color: black,
  },
  farmsFieldsLabel: {
    fontSize: 12,
    fontWeight: '400',
    color: gray600,
  },
  statusLabel: {
    fontSize: 12,
    fontWeight: '600',
    color: black,
    marginTop: 14,
  },
  headerChipsContainer: {
    flex: 1,
    flexDirection: 'row',
    marginTop: 4,
  },
  headerChip: {
    marginRight: 8,
  },
  accordionBodyContainer: {
    flexDirection: 'column',
    marginLeft: 48,
  },
  surfaceLabel: {
    fontSize: 12,
    fontWeight: '400',
    color: gray800,
  },
  subActivityTypeLabel: {
    fontSize: 12,
    fontWeight: '400',
    color: black,
  },
  eiqLabel: {
    fontSize: 12,
    fontWeight: '600',
    color: gray800,
  },
  payLabel: {
    fontSize: 12,
    fontWeight: '400',
    color: gray800,
  },
  estimatedPayLabel: {
    fontSize: 12,
    fontWeight: '400',
    color: gray800,
  },
  estimatedHarvestDateLabel: {
    fontSize: 12,
    fontWeight: '400',
    color: gray800,
  },
  harvestDateLabel: {
    fontSize: 12,
    fontWeight: '400',
    color: gray800,
  },
  farmContainer: {
    flexDirection: 'column',
    marginTop: 10,
  },
  farmNameLabel: {
    fontSize: 12,
    fontWeight: '600',
    color: gray800,
  },
  fieldNameLabel: {
    fontSize: 12,
    fontWeight: '400',
    color: gray800,
  },
  textOpacity: {
    color: gray500,
  },
  textFinished: {
    color: green500,
  },
})
