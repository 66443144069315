import { HeaderSelectionItem } from '@common/components'
import { ItemSelectionContentField } from '@common/components'

export interface establishmentInterface {
  disabled?: boolean
  lots?: LotInterface[]
  farmUuid?: string
}
interface LotInterface {
  _id?: string
  lot?: {
    id?: string
  }
  selected?: boolean
  disabled?: boolean
}

interface IParamsBuild {
  fields: establishmentInterface[]
  itemsSelected: LotInterface[]
  onClickField?: (
    elements: any[],
    indexFarm: number,
    indexField: number
  ) => void
  onClickFarm?: (indexFarm: number) => void
  allDisabled: boolean
  isMultiSelection: boolean
}

export const buildEstablishmentsToSelector = ({
  fields,
  itemsSelected,
  onClickField,
  onClickFarm,
  allDisabled = false,
  isMultiSelection = false,
}: IParamsBuild) => {
  const newEstablishments = fields
    .map((element) => {
      const elementsLots = element.lots ?? []
      let numSelected = 0
      let disabledAll = Boolean(itemsSelected.length && !isMultiSelection)

      const newLots = elementsLots.map((lotElement: LotInterface) => {
        const elementSelected = itemsSelected.some(
          (item) => item._id === lotElement._id
        )

        if (elementSelected) {
          numSelected++
          disabledAll = allDisabled ?? false
          lotElement.selected = true
        }

        return {
          ...lotElement,
          ...lotElement.lot,
          ElementRender: ItemSelectionContentField,
          onClickElement: onClickField,
          farmUuid: element.farmUuid,
          selected: lotElement.selected ?? false,
        }
      })

      if (newLots.length) {
        return {
          ...element,
          selected: numSelected === newLots.length,
          items: newLots,
          disabled: disabledAll,
          ElementRender: HeaderSelectionItem,
          onClickElement: onClickFarm,
        }
      }
    })
    .filter((element) => element)
  return newEstablishments
}
