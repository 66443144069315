const RouterNames = {
  FARM_MAP_TO_URL_WEB_VIEW: 'FarmMapToUrlWebView',
  CREATE_GENERIC_SUPPLY: 'CreateGenericSupply',
  FARMS: 'Farms',
  FARM_MAP: 'FarmMap',
  CROPS: 'Crops',
  CROP_LIST: 'CropList',
  CROP_DETAIL: 'CropDetail',
  CROP_FIELD_STORY: 'FieldStory',
  CROP_CREATE: 'CropCreate',
  CROP_SELECT_RESPONSIBLE: 'CropSelectResponsible',
  CROP_COLLABORATOR_CREATE: 'CollaboratorCreate',
  CROP_LOTS_ADD: 'LotsAdd',
  CROP_CREATE_CONFIRM: 'CropCreateConfirm',
  CROP_COLLABORATOR_LIST: 'CropCollaborators',
  CROP_COLLABORATOR_ADD: 'CropCollaboratorsAdd',
  CROP_COLLABORATOR_DELEGATE_SIGNATURES: 'CollaboratorsDelegateSignatures',
  CROP_COLLABORATOR_UNLINK: 'UnlinkCollaborator',
  CROP_FARM_LIST: 'CropFarmList',
  CROP_FARM_FIELD_DETAIL: 'CropFarmFieldDetails',
  CROP_FARM_FIELD_EDIT: 'FarmLotEditName',
  CROP_FILTER: 'Filters',
  LICENSES: 'Licenses',
  LICENSES_LIST: 'LicenseList',
  LICENSES_DETAIL: 'LicenseDetail',
  LICENSES_DETAIL_READ_ONLY: 'LicenseDetailReadOnly',
  LICENSES_SIGN: 'LicenseSignV2',
  LICENSES_FIELDS_ADD: 'LicenseFieldsAdd',
  LICENSES_SUMMARY: 'LicenseSummary',
  LICENSES_SUB_LICENSE_SELECT_COMPANIES: 'SubLicenseSelectCompanies',
  LICENSES_SUMMARY_LANDSCAPE_SUSTAINABILITY: 'SummaryLandscapeSustainability',
  ACTIVITY_UPDATE: 'ActivityUpdate',
  ACTIVITY_CREATE: 'ActivityCreate',
  ACTIVITY_CREATE_CONFIRM: 'ActivityCreateConfirm',
  ACTIVITY_MINIMUM_UNIT_LOT: 'ActivityMinimumUnitLot',
  ACTIVITY_LOT_SELECTOR: 'LotsSelectorModal',
  ACTIVITY_FARMS_LIST_SELECT_BY_CROP: 'FarmsListSelectByCrop',
  ACTIVITY_COMMON_OPTIONAL_PLANED: 'CommonOptionalPlanned',
  ACTIVITY_COMMON: 'CommonActivities',
  ACTIVITY_COMMON_DETAIL: 'CommonActivitiesDetail',
  ACTIVITY_LOT_ADD: 'ActivityLotsAdd',
  ACTIVITY_FINISHED: 'ActivityFinished',
  ACTIVITY_APPLICATION_DETAIL: 'ActivityApplicationDetails',
  ACTIVITY_ACHIEVEMENT_LIST: 'AchievementsActivityList',
  ACHIEVEMENT_SCREEN: 'AchievementsScreen',
  ACTIVITY_MODAL_ERROR_DEEP_LINK: 'ModalErrorDeepLinks',
  ACTIVITY_COLLABORATOR_SELECTOR: 'CollaboratorsSelectorModal',
  PROFILE: 'Profile',
  NOTIFICATION: 'Notification',
  NOTIFICATION_LIST: 'NotificationList',
  NOTIFICATION_FILTERS: 'NotificationFilters',
  COMPANY: 'Company',
  COMPANY_LIST: 'CompanyList',
  COMPANY_LIST_UCROPIT: 'CompanyListUcropit',
  COMPANY_ADD: 'CompanyAdd',
  COMPANY_ADD_COLLABORATOR_LIST: 'CollaboratorsListNewCompany',
  COMPANY_DETAIL: 'CompanyDetail',
  COMPANY_COLLABORATOR_LIST: 'CollaboratorListCompany',
  COMPANY_COLLABORATOR_ADD: 'AddCollaborator',
  COMPANY_COLLABORATOR_ADD_ADVISER: 'AddAdviser',
  COMPANY_EDIT: 'CompanyEdit',
  COMPANY_SERVICE_INTEGRATIONS: 'CompanyServiceIntegrationsSelector',
  COMPANY_SERVICE_INTEGRATIONS_LIST: 'CompanyIntegrationAppList',
  COMPANY_SERVICE_INTEGRATIONS_DETAIL:
    'CompanyServiceIntegrationAccountSelector',
  COMPANY_SERVICE_INTEGRATIONS_ADD: 'CompanyIntegrationAgreement',
  COMPANY_SERVICE_INTEGRATIONS_LINK: 'CompanyServiceIntegrationLink',
  COMPANY_SERVICE_INTEGRATIONS_LOGIN: 'CompanyServiceIntegrationAdd',
  COMPANY_SERVICE_INTEGRATIONS_UNLINK: 'CompanyServiceIntegrationUnlink',
  COMPANY_APP_INTEGRATION_DETAIL: 'CompanyAppIntegratorDetail',
  COMPANY_APP_INTEGRATION_WEB_VIEW: 'WebViewIntegrator',
  SETUP: 'Setup',
  OFFLINE_QUEUE: 'OfflineQueue',
  SETTINGS: 'Settings',
  SETTINGS_LIST: 'SettingsList',
  ACTIVITY_LIST: 'ActivityList',
  ACTIVITY_LIST_FILTER: 'FilterActivities',
  TRADERS: 'traders',
  QR: 'qr',
  POLYGONS_TRADER: 'PolygonTrader',
  QUICK_SIGHT_TRADER: 'QuickSightTrader',
  SEARCHER_SUPPLIES: 'SearcherSupplies',
  SUPPLY: 'Supply',
  SUPPLY_LOADER: 'SupplyLoaderScreen',
  SUPPLY_DETAIL: 'SupplyDetail',
  SELECT_ITEMS_TO_PARAMETER: 'SelectItemsToParameters',
  SELECT_ITEMS_WITH_MULTI_LEVELS: 'SelectItemsWithMultiLevels',
  CREATE_PIN: 'CreatePin',
  WEB_VIEW: 'WebView',
}

export default RouterNames
