import { useState, createContext, useEffect, useContext } from 'react'
import { Platform } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { PropTypes } from 'prop-types'
import { debounce } from 'lodash'

import useNetwork from '@utils/network'
import useOfflineTypes from '../offline/queries/types'
import useOfflineSubTypeActivities from '../offline/queries/subTypeActivities'
import { LanguageContext } from './language'
import { orderMenuOptions } from '@modules/crops/screens/CropList/v1/utils'
import {
  INIT_NOTIFICATION_FILTER,
  cropTypesNotification,
  activityTypesNotification,
} from '@modules/notifications/screens/Filters/v1/utils'
import { cropStages } from '@utils/constants'
import { EEvidenceConcepts } from '@common/enum'
import { useSelector } from 'react-redux'
import {
  fetchPaddyWaterCultivations,
  fetchPaddyWaterPreseasons,
} from '../modules/activities/services'
import { useAuth } from '@common/hooks'

export const CommonContext = createContext({})

export const CommonProvider = ({ children }) => {
  const { doRequest } = useNetwork()
  const { activityTypes } = useSelector((state) => state.activityTypesReducer)
  const { isConnected } = useSelector((state) => state.connectionReducer)
  const { cropTypes } = useSelector((state) => state.cropTypesReducer)
  const [showDeleteIcon, setShowDeleteIcon] = useState(false)
  const [disableDeleteIcon, setDisableDeleteIcon] = useState(false)
  const [showDeleteSupplyIcon, setShowDeleteSupplyIcon] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [isNotificationModalVisible, setIsNotificationModalVisible] =
    useState(false)
  const [notification, setNotification] = useState({})
  const { user, config, isAuth } = useAuth()
  const { i18n } = useContext(LanguageContext)

  const [verificationTypes, setVerificationTypes] = useState([])
  const [roleTypes, setRoleTypes] = useState([])

  const [stages] = useState(cropStages)
  const [evidenceConcept, setEvidencesConcept] = useState([])
  const [subTypeActivities, setSubTypeActivities] = useState([])
  const [servicesIntegration, setServicesIntegration] = useState([])
  const [filters, setFilters] = useState({
    cropTypes: [],
    companies: [],
    collaborators: [],
    cropVolume: undefined,
    allCropTypes: [],
    allCompanies: [],
    allCollaborators: [],
    hasAnyFilterActive: false,
  })
  const [countries, setCountries] = useState([])
  const [optionsCountries, setOptionsCountry] = useState([])
  const { storeType, getTypes } = useOfflineTypes()
  const [isFetching, setIsFetching] = useState(false)
  const { syncSubTypeActivities, getOfflineSubTypeActivities } =
    useOfflineSubTypeActivities()
  const [unitTypesSupplies, setUnitTypeSupplies] = useState([])
  const [orderCropsBy, setOrderCropsBy] = useState(orderMenuOptions[0])
  const [cropsFilter, setCropsFilter] = useState([])
  const [isFetchingCropsFilter, setIsFetchingCropsFilter] = useState(false)
  const [fetchCommonIsComplete, setFetchCommonIsComplete] = useState(false)
  const [isFetchingCommonSuccess, setIsFetchingCommonSuccess] = useState(false)
  const [errorDeepLink, setErrorDeepLink] = useState(null)
  const [evidenceCft, setEvidenceCft] = useState(null)
  const [isSupportsDatabase, setIsSupportsDatabase] = useState(false)
  const [isDraftsSynchronizing, setIsDraftsSynchronizing] = useState(false)
  const [paddyWaterPreseasons, setPaddyWaterPreseasons] = useState([])
  const [paddyWaterCultivations, setPaddyWaterCultivations] = useState([])

  const [notificationFilters, setNotificationFilters] = useState(
    INIT_NOTIFICATION_FILTER ?? {}
  )

  const fetchUseCallback = async (url) => {
    return await doRequest({
      method: 'GET',
      url,
    })
  }

  async function fetchRoleTypes() {
    let data = []
    if (isConnected) {
      const response = await fetchUseCallback('commons/roles')

      data = response.data

      if (Platform.OS !== 'web') {
        await storeType('roletypes', data)
      }
    } else {
      data = await getTypes('roletypes')
    }

    setRoleTypes(
      data.map((el) => ({
        isInactive: el.isInactive,
        label: el.keyLabel,
        value: el.value,
        assignable: el.assignable ?? [],
        canMarkFlags: el.canMarkFlags ?? [],
      }))
    )
  }

  async function fetchVerificationTypes() {
    let data = []
    if (isConnected) {
      const response = await fetchUseCallback('commons/verification-types')

      data = response.data

      if (Platform.OS !== 'web') {
        await storeType('verificationTypes', data)
      }
    } else {
      data = await getTypes('verificationTypes')
    }

    setVerificationTypes(
      data.map((el) => ({
        label: el.keyLabel,
        value: el._id,
        key: el.key,
      }))
    )
  }

  async function fetchEvidenceConcept() {
    let data = []
    if (isConnected) {
      const response = await fetchUseCallback('commons/concepts')

      data = response.data

      if (Platform.OS !== 'web') {
        await storeType('concepts', data)
      }
    } else {
      data = await getTypes('concepts')
    }
    setEvidencesConcept(
      data.map((el) => ({
        label: el.keyLabel,
        value: el.keyLabel,
        code: el.code,
        _id: el._id,
        tagActivityType: el?.tagActivityType,
      }))
    )
    const resultEvidenceCft = data.find(
      (evidence) => evidence.code === EEvidenceConcepts['0013']
    )
    if (resultEvidenceCft) {
      setEvidenceCft(resultEvidenceCft)
    }
  }

  async function fetchServiceIntegration() {
    const response = await fetchUseCallback('commons/integrations')
    setServicesIntegration(
      response.data.map((el) => ({
        label: el.name,
        value: el.erpAgent,
        description: el.description,
        conditions: el.conditions,
      }))
    )
  }

  async function fetchSubTypeActivities() {
    let data = []

    if (isConnected) {
      const response = await fetchUseCallback('activities/subType')

      data = response.data

      if (Platform.OS !== 'web') {
        syncSubTypeActivities()
      }
    } else {
      data = await getOfflineSubTypeActivities()
    }

    setSubTypeActivities(
      data.map((el) => ({
        label: el.codeLabel,
        activityType: el.activityType,
        value: el._id,
        codeLabel: el.codeLabel,
        parents: el.parents,
        key: el.key,
      }))
    )
  }

  async function fetchCountries() {
    let data = []

    if (isConnected) {
      const response = await fetchUseCallback('commons/countries')

      data = response.data

      if (Platform.OS !== 'web') {
        await storeType('countries', data)
      }
    } else {
      data = await getTypes('countries')
    }

    setCountries(data)
    setOptionsCountry(
      data.map((element) => {
        return {
          label: element.name,
          value: element._id,
        }
      })
    )
  }

  const fetchUnitTypesSupplies = async () => {
    let data = []

    if (isConnected) {
      const response = await fetchUseCallback('commons/unit-types-supplies')

      data = response.data

      if (Platform.OS !== 'web') {
        await storeType('unitTypesSupplies', data)
      }
    } else {
      data = await getTypes('unitTypesSupplies')
    }

    setUnitTypeSupplies(data)
  }

  async function fetchCropsForFilter() {
    try {
      setIsFetchingCropsFilter(true)
      const { companySelected, companyAdmin } = config
      const identifier = companyAdmin?.identifier || companySelected?.identifier
      const companyId = companySelected?._id
      let url = 'crops'
      if (identifier) {
        url = `${url}?identifier=${identifier}`
      }
      if (companyId) {
        url = `${url}&companyId=${companyId}`
      }
      const response = await fetchUseCallback(url)

      setCropsFilter(response.data)
    } catch (err) {
      console.warn(err)
    } finally {
      setIsFetchingCropsFilter(false)
    }
  }

  const fetchInfoFlood = async () => {
    let paddyWaterCultivationsResponse = []
    let paddyWaterPreseasonsResponse = []

    if (isAuth) {
      paddyWaterCultivationsResponse = await fetchPaddyWaterCultivations()
      paddyWaterPreseasonsResponse = await fetchPaddyWaterPreseasons()
    }

    const resultPaddyWaterCultivations = paddyWaterCultivationsResponse.map(
      (el) => ({
        label: el.keyLabel,
        value: el._id,
      })
    )
    const resultPaddyWaterPreseasons = paddyWaterPreseasonsResponse.map(
      (el) => ({
        label: el.keyLabel,
        value: el._id,
      })
    )
    setPaddyWaterCultivations(resultPaddyWaterCultivations)
    setPaddyWaterPreseasons(resultPaddyWaterPreseasons)
  }

  const initialFetch = async () => {
    if (isFetching) {
      return
    }

    setIsFetching(true)

    try {
      await Promise.all([
        fetchCountries(),
        fetchVerificationTypes(),
        fetchEvidenceConcept(),
        fetchRoleTypes(),
        fetchServiceIntegration(),
        fetchSubTypeActivities(),
        fetchUnitTypesSupplies(),
        fetchInfoFlood(),
      ])
    } catch (error) {
      console.error(error)
    }

    setIsFetching(false)
  }

  async function resetFilters(from) {
    if (from === 'HEADER' && filters.hasAnyFilterActive) {
      setFilters({
        ...filters,
        cropTypes: [],
        companies: [],
        collaborators: [],
        cropVolume: undefined,
        hasAnyFilterActive: false,
      })

      return
    }

    setFilters({
      ...filters,
      cropTypes: [],
      companies: [],
      collaborators: [],
      cropVolume: undefined,
      allCropTypes: [],
      allCompanies: [],
      allCollaborators: [],
      hasAnyFilterActive: false,
    })
  }

  function getNotificationFilterValues() {
    const { farmListSelected, cropName, cropTypes, activityTypes } =
      notificationFilters.values

    const farmIds = farmListSelected.map((farm) => farm._id)

    const cropNames = []
    if (cropName.trim().length) {
      cropNames.push(cropName)
    }

    const newCropTypes = cropTypes
      .filter((cropType) => cropType.selected)
      .map((cropType) => cropType.key)

    const newActivityTypes = activityTypes
      .filter((activityType) => activityType.selected)
      .map((activityType) => activityType.key)

    return {
      farmIds,
      cropNames: cropNames,
      cropTypes: newCropTypes,
      activityTypes: newActivityTypes,
    }
  }

  async function resetNotificationFilters() {
    if (
      typeof notificationFilters.callbackResetNotificationFilter === 'function'
    ) {
      notificationFilters.callbackResetNotificationFilter()
    }

    setNotificationFilters(INIT_NOTIFICATION_FILTER)
  }

  async function syncCropTypesWithNotifications() {
    const cropTypesNotificationFilter = cropTypesNotification(cropTypes)

    setNotificationFilters((filters) => {
      const newFilters = {
        ...filters,
        values: {
          ...filters.values,
          cropTypes: cropTypesNotificationFilter,
        },
      }

      return newFilters
    })
  }

  async function resetOrderCropsBy() {
    setOrderCropsBy(orderMenuOptions[0])
  }

  const closeDeleteModal = () => {
    setShowDeleteModal(false)
  }

  const displayToggleModalNotification = ({
    text,
    duration = 15000,
    extraStyle = {},
  }) => {
    if (text) {
      setNotification({
        text,
        duration,
        extraStyle,
      })
    }

    setIsNotificationModalVisible(!isNotificationModalVisible)
  }

  const closeModalNotification = () => {
    setIsNotificationModalVisible(false)
  }

  const onEnableDeleteIcon = () => {
    setDisableDeleteIcon(false)
  }

  const onDisableDeleteIcon = () => {
    setDisableDeleteIcon(true)
  }

  const allCountryData = (countryId) => {
    return countries.find((element) => element._id === countryId)
  }

  const clearErrorDeepLink = () => {
    setErrorDeepLink(null)
  }

  async function syncActivityTypesWithNotifications() {
    const activityTypesNotificationFilter =
      activityTypesNotification(activityTypes)

    setNotificationFilters((filters) => {
      const newFilters = {
        ...filters,
        values: {
          ...filters.values,
          activityTypes: activityTypesNotificationFilter,
        },
      }

      return newFilters
    })
  }

  useEffect(() => {
    syncCropTypesWithNotifications()
  }, [cropTypes])

  useEffect(() => {
    if (activityTypes?.length) {
      syncActivityTypesWithNotifications()
    }
  }, [activityTypes])

  useEffect(() => {
    if (
      verificationTypes.length &&
      roleTypes.length &&
      evidenceConcept.length &&
      subTypeActivities.length &&
      servicesIntegration.length &&
      countries.length &&
      optionsCountries.length &&
      unitTypesSupplies.length
    ) {
      setFetchCommonIsComplete(true)
    }
  }, [
    verificationTypes,

    roleTypes,
    evidenceConcept,
    subTypeActivities,
    servicesIntegration,
    countries,
    optionsCountries,
    unitTypesSupplies,
    cropsFilter,
  ])

  useEffect(
    debounce(() => {
      const locale = isAuth ? user?.config?.languageSelected : i18n?.locale

      if (!locale) {
        return
      }

      AsyncStorage.setItem('language', locale).then(() => {
        initialFetch()
      })
    }, 100),
    [i18n?.locale, user?.config?.languageSelected, isAuth]
  )

  return (
    <CommonContext.Provider
      value={{
        verificationTypes,
        evidenceConcept,
        roleTypes,
        servicesIntegration,
        subTypeActivities,
        filters,
        countries,
        setFilters,
        resetFilters,
        showDeleteIcon,
        disableDeleteIcon,
        onDisableDeleteIcon,
        onEnableDeleteIcon,
        showDeleteSupplyIcon,
        setShowDeleteSupplyIcon,
        setShowDeleteIcon,
        showDeleteModal,
        setShowDeleteModal,
        closeDeleteModal,
        isNotificationModalVisible,
        displayToggleModalNotification,
        closeModalNotification,
        notification,
        optionsCountries,
        setVerificationTypes,
        allCountryData,
        unitTypesSupplies,
        orderCropsBy,
        setOrderCropsBy,
        resetOrderCropsBy,
        fetchVerificationTypes,
        cropsFilter,
        fetchCropsForFilter,
        isFetchingCropsFilter,
        notificationFilters,
        setNotificationFilters,
        resetNotificationFilters,
        getNotificationFilterValues,
        fetchCommonIsComplete,
        isFetchingCommonSuccess,
        setIsFetchingCommonSuccess,
        setErrorDeepLink,
        clearErrorDeepLink,
        errorDeepLink,
        evidenceCft,
        isSupportsDatabase,
        setIsSupportsDatabase,
        isDraftsSynchronizing,
        setIsDraftsSynchronizing,
        paddyWaterPreseasons,
        paddyWaterCultivations,
        stages,
      }}
    >
      {children}
    </CommonContext.Provider>
  )
}

CommonProvider.propTypes = {
  children: PropTypes.object,
}
