export const parseDataToValidateAchievementUnplanned = (values, userId) => {
  const dataToSend = {
    draftId: values?.draftId,
    name: values.name,
    surface: values.surface,
    type: values.type,
    cropId: values.cropId,
    subTypeActivity: values.subTypeActivity?.value,
    tag: values.tag,
    lots: values.lots.value.map((element) => element._id),
    isDirectAchievement: true,
    lotsWithSurface: values.lots.value.map((element) => ({
      lot: element._id,
      surfaceRealized: element.surface,
      tag: element.tag,
    })),
    signers: values.signers.map((element) => {
      return {
        ...element,
        signed: element.userId === userId,
        dateToSign: element.userId !== userId ? new Date() : undefined,
      }
    }),
    supplies: values.supplies?.map((element) => ({
      ...element,
      typeId: element.typeId?._id ?? element.typeId,
      supply: element.supplyId || element.supply || element._id,
      typeSupply:
        typeof element.typeId === 'object'
          ? element?.typeId
          : element?.typeSupply,
    })),
    dateAchievement: values.dateAchievement?.value,
    evidences: values.evidences.map((element) => ({
      ...element,
      user: element.user?._id,
    })),
    verificationFile: values.verificationFile
      ? {
          name: values.verificationFile.originalName,
          uploadedBy: values.verificationFile.uploadedBy._id,
          uploadedAt: values.verificationFile.uploadedAt,
        }
      : undefined,
    seedDryContent: values?.seedDryContent
      ? Number(values?.seedDryContent)
      : undefined,
    dataForCFT: values?.dataForCFT,
    establishmentFormId: values?.establishmentFormId,
    emissionCO2: values?.emissionCO2,
    summaryEmissionCO2: values?.summaryEmissionCO2,
    farms: values?.farms?.map((farm) => farm.farm),
    irrigationWaterSource: values.irrigationWaterSource?.value,
    irrigationPowerSource: values.irrigationPowerSource?.value,
    typeAgreement: values.typeAgreement,

    dateHarvest: values.dateHarvest?.value,
    dateObservation: values.dateObservation?.value,
    dateEstimatedHarvest: values.dateEstimatedHarvest?.value,
    unitType: values.unitType?.value,

    verificationType: values.verificationType?.value,
    pay: values?.pay,
    volume: values?.volume,
    unitVolume: values?.unitVolume,
    observation: values.observation,
    observations: values.observations,
    storages: values.storages,
    weatherType: values.weatherType,
    temperature: values.temperature,
    soilType: values.soilType,
    landType: values.landType,
    humidityType: values.humidityType,
    timeFloodUnderCrop: values.timeFloodUnderCrop,
    unitTime: values.unitTime,
    paddyWaterCultivation: values.paddyWaterCultivation,
    paddyWaterPreseason: values.paddyWaterPreseason,
    formResponse: values.formResponse?.length ? values.formResponse : undefined,
    harvestAction: values.harvestAction,
  }

  if (values.pumpingDepth) dataToSend.pumpingDepth = Number(values.pumpingDepth)

  if (values.waterConsumption)
    dataToSend.waterConsumption = Number(values.waterConsumption)

  if (values.horizontalWateringDepth)
    dataToSend.horizontalWateringDepth = Number(values.horizontalWateringDepth)

  return dataToSend
}
