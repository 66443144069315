export const parseDataToValidate = (values, userId) => {
  const dataToSend = {
    draftId: values.draftId,
    crop: values.cropId,
    name: values.name,
    type: values.type,
    status: values.status,
    lots: values.lots.value.map((element) => element._id),
    lotsWithSurface: values.lots.value.map((element) => ({
      lot: element._id,
      surfacePlanned: element.surface,
      tag: element.tag,
      farm: element.farm,
    })),
    surface: values.surface,
    dateHarvest: values.dateHarvest?.value,
    dateObservation: values.dateObservation?.value,
    dateEstimatedHarvest: values.dateEstimatedHarvest?.value,
    unitType: values.unitType?.value,
    typeAgreement: values.typeAgreement?.value,
    verificationType: values.verificationType?.value,
    pay: values.pay,
    volume: values?.volume,
    unitVolume: values?.unitVolume,
    observation: values.observation,
    observations: values.observations,
    storages: values.storages,
    evidences: values.evidences.map((element) => ({
      ...element,
      user: element.user?._id,
    })),
    signers: values.signers.map((element) => {
      if (element.userId !== userId) {
        return {
          ...element,
          signed: false,
          dateToSign: new Date(),
        }
      }
      return element
    }),
    verificationFile: values.verificationFile
      ? {
          name: values.verificationFile.originalName,
          uploadedBy: values.verificationFile.uploadedBy._id,
          uploadedAt: values.verificationFile.uploadedAt,
        }
      : undefined,
    seedDryContent: values?.seedDryContent
      ? Number(values?.seedDryContent)
      : undefined,
    dataForCFT: values?.dataForCFT,
    establishmentFormId: values?.establishmentFormId,
    emissionCO2: values?.emissionCO2,
    summaryEmissionCO2: values?.summaryEmissionCO2,
    farms: values?.farms?.map((farm) => farm.farm),
    irrigationWaterSource: values.irrigationWaterSource?.value,
    pumpingDepth: values.pumpingDepth,
    waterConsumption: values.waterConsumption,
    horizontalWateringDepth: values.horizontalWateringDepth,
    irrigationPowerSource: values.irrigationPowerSource?.value,
    dateAchievement: values.dateAchievement?.value,
    subTypeActivity: values.subTypeActivity?.value,
    weatherType: values.weatherType,
    temperature: values.temperature,
    soilType: values.soilType,
    landType: values.landType,
    humidityType: values.humidityType,
    formResponse: values.formResponse,
    harvestAction: values.harvestAction,
  }
  return dataToSend
}
