export const harvestFormValues = ({
  cropId,
  activityId,
  company,
  draftId,
  tag,
  activityType,
}) => ({
  draftId: draftId,
  cropId: cropId,
  activityId: activityId,
  company: company,
  tag: tag,
  name: activityType?.label,
  type: activityType?.value,
  status: 'DONE',
  lots: {
    value: [],
    label: '',
  },
  surface: '',
  dateHarvest: {
    value: '',
    label: '',
  },
  unitType: {
    value: '',
    label: '',
  },
  volume: '',
  unitVolume: {
    value: '',
    label: '',
  },
  pay: '',
  signers: [],
  evidences: [],
  files: [],
  storages: [],
  subTypeActivity: {
    value: '',
    label: '',
  },
  harvestAction: {
    value: '',
    label: '',
  },
})
