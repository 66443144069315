import GENERAL_INFO_CARD from '../../components/GeneralInfoCard/translate/pt'
import ACTIVITY_CARD from '../../components/ActivityCard/translate/pt'
import YIELD_CARD from '../../components/YieldCard/translate/pt'
import STORAGE_CARD from '../../components/StorageCard/translate/pt'
import OBSERVATION_CARD from '../../components/ObservationCard/translate/pt'
import EVIDENCE_CARD from '../../components/EvidenceCard/translate/pt'
import COLLABORATOR_CARD from '../../components/CollaboratorCard/translate/pt'
import SUPPLY_SEED_CARD from '../../components/SupplySeedCard/translate/pt'
import SUPPLY_FERTILIZER_CARD from '../../components/SupplyFertilizerCard/translate/pt'
import SUPPLY_PHYTOTHERAPIC_CARD from '../../components/SupplyPhytotherapicCard/translate/pt'
import SUPPLY from '../../components/Supply/translate/pt'
import VALIDATE_BUTTON from '../../components/ValidateButton/translate/pt'
import MODIFY_BUTTON from '../../components/ModifyButton/translate/pt'

export default {
  NAVIGATION: {
    TITLE: 'Resumo',
  },
  COMPONENTS: {
    GENERAL_INFO_CARD,
    ACTIVITY_CARD,
    YIELD_CARD,
    STORAGE_CARD,
    OBSERVATION_CARD,
    EVIDENCE_CARD,
    COLLABORATOR_CARD,
    SUPPLY_SEED_CARD,
    SUPPLY_FERTILIZER_CARD,
    SUPPLY_PHYTOTHERAPIC_CARD,
    SUPPLY,
    VALIDATE_BUTTON,
    MODIFY_BUTTON,
  },
  IRRIGATION: {
    SOURCE_OF_WATER: 'Fonte de água',
    PUMPING_DEPTH: 'Profundidade de bombeamento',
    WATER_CONSUMPTION: 'Consumo de água',
    HORIZONTAL_IRRIGATION_DISTANCE: 'Distância de irrigação horizontal',
    SOURCE_OF_ENERGY: 'Fonte de energia',
  },
  HARVEST: {
    HARVEST_TYPE: 'Tipo de colheita',
    HARVEST_ACTION: 'Ação de colheita',
  },
}
