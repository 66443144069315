import SNACKBAR from '../../components/snackbar/SnackbarRejectedActivity/translate/en'
import TAG_NEW from '../../components/tagNew/translate/en'
import LOST_EMPTY from '../../components/LotsEmpty/translate/en'
import OFFLINE_EMPTY from '../../components/OfflineEmpty/translate/en'
import CROP_MENU from '../../components/CropMenuWeb/translate/en'
import MODAL_ERRORS_DEEP_LINKS from '../../ModalErrorsDeepLinks/translate/en'
import STANDARD_DEVIATION from '../../components/StandardDeviation/translate/en'
import UNASSOCIATE_LICENSE_MENU from '../../components/menu/UnassociatedLicenseMenu/translate/en'
import MODAL_TERMS from '../../components/modals/ModalTerms/translate/en'
import ACTIVITY_CONDITIONS from '../../utils/activityConditions/translate/en'

export default {
  SNACKBAR,
  TAG_NEW,
  LOST_EMPTY,
  OFFLINE_EMPTY,
  COMPONENTS: {
    CROP_MENU,
    UNASSOCIATE_LICENSE_MENU,
    MODAL_TERMS,
  },
  MODAL_ERRORS_DEEP_LINKS,
  STANDARD_DEVIATION,
  ACTIVITY_CONDITIONS,
  CONGRATULATION: 'Congratulations!',
}
